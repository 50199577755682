import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { usePathname, useRouter } from '@polyai/common/hooks/useRouter';

import api from 'api';
import { AssistantType } from 'api/resources/accounts/types';
import { AccountsResponse } from 'api/types';
import { setAccountId } from 'ducks/account/accountSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useIsFeatureOn } from './useFeatureSwitch';

const isPersonalAccount = (name: string, userEmail?: string) =>
  userEmail && name.includes(userEmail);

const isPlatformAccount = (id: string) => id === 'PLATFORM';

const reorderAccounts = (accounts: AccountsResponse, userEmail?: string) => {
  const accountPersonal = accounts.find((account) =>
    isPersonalAccount(account.name, userEmail),
  );
  const accountPlatform = accounts.find((account) =>
    isPlatformAccount(account.id),
  );

  let reorderedAccounts: AccountsResponse = [];
  if (accountPersonal) {
    reorderedAccounts.push(accountPersonal);
  }
  if (accountPlatform) {
    reorderedAccounts.push(accountPlatform);
  }

  reorderedAccounts = [
    ...reorderedAccounts,
    ...accounts
      .filter(
        (account) =>
          !isPersonalAccount(account.name, userEmail) &&
          !isPlatformAccount(account.id),
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return reorderedAccounts;
};

const useAccounts = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();
  const user = useAppSelector((state) => state.auth.user);
  const { isFeatureOn } = useIsFeatureOn();
  const isPortalProjectsFeatureOn = isFeatureOn('portal_projects_in_jupiter');

  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const { replaceUrl } = useRouter();

  const { data: unorderedAccounts, isLoading: isLoadingAccounts } = useQuery(
    ['accounts'],
    api.getAccounts,
    {
      retry: false,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      onSuccess: (accounts) => {
        if (accounts.length && !accountId && pathname === '/') {
          const filteredAccounts = isPortalProjectsFeatureOn
            ? accounts
            : accounts.filter(
                (account) => account.type !== AssistantType.INTENT,
              );
          const reorderedAccounts = reorderAccounts(
            filteredAccounts,
            user?.email,
          );
          replaceUrl(`/${reorderedAccounts[0].id}`);
        }
      },
    },
  );

  useEffect(() => {
    if (accountId) {
      dispatch(setAccountId(accountId));
    }
  }, [accountId, dispatch]);

  const accounts = useMemo(() => {
    if (!unorderedAccounts) {
      return [];
    }

    const filteredAccounts = isPortalProjectsFeatureOn
      ? unorderedAccounts
      : unorderedAccounts.filter(
          (account) => account.type !== AssistantType.INTENT,
        );

    return reorderAccounts(filteredAccounts, user?.email);
  }, [unorderedAccounts, user?.email, isPortalProjectsFeatureOn]);

  return { accounts, isLoadingAccounts };
};

export default useAccounts;
