import React, {
  cloneElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { PopupActions } from "reactjs-popup/dist/types";

import Button, { ButtonVariant } from "components/atoms/Button";
import Callout, { CalloutProps } from "components/atoms/Callout";
import { IconButton } from "components/atoms/IconButton";
import { CloseIcon } from "components/atoms/Icons";
import { BaseIcon } from "components/atoms/Icons/BaseIcon";
import { Stack } from "components/atoms/Stack";
import Modal, { ModalProps } from "components/molecules/Modal";

import { useBreakpoint } from "./../../../hooks/useBreakpoint";

import * as Styled from "./ConfirmationModal.styled";

type ButtonVariants = keyof typeof ButtonVariant;

const getSubtitle = (subtitle?: ReactNode) => {
  if (!subtitle) return null;
  if (typeof subtitle === "string") {
    return <Styled.Subtitle>{subtitle}</Styled.Subtitle>;
  }

  return subtitle;
};

export interface ConfirmationModalProps extends Omit<ModalProps, "children"> {
  title?: string | JSX.Element;
  subtitle?: ReactNode;
  content?: string | JSX.Element;
  onConfirm?: () => boolean | void;
  buttonText?: string;
  secondaryButtonText?: string;
  cancelText?: string;
  leftButtonText?: string;
  confirmButtonVariant?: ButtonVariants;
  secondaryButtonVariant?: ButtonVariants;
  cancelButtonVariant?: ButtonVariants;
  onClose?: () => void;
  hasCloseIcon?: boolean;
  confirmDisabled?: boolean;
  enableLoading?: boolean;
  confirmRequesting?: boolean;
  className?: string;
  contentStyle?: any;
  disableConfirmOnEnter?: boolean;
  hasCancelButton?: boolean;
  closeOnEscape?: boolean;
  onConfirmDDActionName?: string;
  onCancelDDActionName?: string;
  when?: boolean;
  skipCloseAndConfirm?: boolean;
  skipCloseModal?: boolean;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: (e?: any) => void;
  onSecondaryButtonClick?: () => void;
  confirmButtonIcon?: typeof BaseIcon;
  confirmButtonIconPlacement?: "left" | "right" | "both";
  calloutProps?: CalloutProps;
}
export const ConfirmationModal: React.FC<ConfirmationModalProps> = React.memo(
  ({
    trigger,
    title,
    subtitle,
    content,
    onConfirm,
    buttonText = "Confirm",
    secondaryButtonText,
    cancelText = "Cancel",
    confirmButtonVariant = "PRIMARY",
    secondaryButtonVariant = "SECONDARY",
    cancelButtonVariant = "SECONDARY",
    onClose,
    onOpen,
    className,
    onConfirmDDActionName,
    onCancelDDActionName,
    hasCloseIcon = false,
    hasCancelButton = true,
    confirmDisabled = false,
    enableLoading = false,
    confirmRequesting = false,
    disableConfirmOnEnter = false,
    closeOnEscape = true,
    size = "sm",
    when = true,
    skipCloseAndConfirm = false,
    skipCloseModal = false,
    onCancelButtonClick,
    onConfirmButtonClick,
    onSecondaryButtonClick,
    confirmButtonIcon,
    confirmButtonIconPlacement,
    calloutProps,
    ...props
  }: ConfirmationModalProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const popupRef = useRef<PopupActions>(null);
    const close = popupRef.current?.close;
    const isDesktop = useBreakpoint("sm");
    const confirmAndClose = useCallback(
      (
        event: KeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        event.preventDefault();
        event.stopPropagation();
        if (disableConfirmOnEnter && (event as KeyboardEvent).key === "Enter") {
          return;
        }
        const confirmed = onConfirm?.();
        if (confirmed === false) {
          return;
        }
        setConfirmed(true);
        if (!enableLoading) {
          close?.();
        }
      },
      [disableConfirmOnEnter, onConfirm, enableLoading, close]
    );

    const onKeyDown = useCallback(
      (event: KeyboardEvent) => {
        event.key === "Enter" && isOpen && confirmAndClose(event);
      },
      [isOpen, confirmAndClose]
    );

    useEffect(() => {
      if (confirmed && !confirmRequesting) {
        close?.();
        setConfirmed(false); // reset state
      }
    }, [close, confirmRequesting, confirmed]);

    useEffect(() => {
      window.addEventListener("keydown", onKeyDown);
      return () => window.removeEventListener("keydown", onKeyDown);
    }, [onKeyDown]);

    const onOpenHandler = useCallback(() => {
      setIsOpen(true);
      onOpen?.();
    }, [onOpen]);

    const onCloseHandler = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose]);

    if (!when && trigger && React.isValidElement(trigger)) {
      return cloneElement(trigger, { onClick: onConfirm } as any);
    }

    const hasSecondaryButton = !!secondaryButtonText;

    const subtitleToRender = getSubtitle(subtitle);

    return (
      <Modal
        ref={popupRef}
        className={className}
        closeOnEscape={closeOnEscape}
        size={size}
        trigger={trigger}
        onClose={onCloseHandler}
        onOpen={onOpenHandler}
        {...props}
      >
        <Styled.ModalWrapper data-test-id="confirmation-modal">
          <Stack direction="column" spacing="sm1">
            <Styled.HeaderWrapper>
              {title ? (
                <Styled.ModalTitle as="h2">{title}</Styled.ModalTitle>
              ) : null}
              {hasCloseIcon && (
                <IconButton onClick={close}>
                  <CloseIcon color="iconSecondary" />
                </IconButton>
              )}
            </Styled.HeaderWrapper>
            {subtitleToRender}
          </Stack>
          {calloutProps && <Callout {...calloutProps} />}
          {Boolean(content) && (
            <Styled.ModalContent
              data-test-id="input-field"
              direction="column"
              spacing="sm3"
            >
              {content}
            </Styled.ModalContent>
          )}

          <Styled.Actions
            direction={isDesktop ? "row" : "column-reverse"}
            hasLeftButton={hasSecondaryButton}
            spacing={isDesktop ? "none" : "xs3"}
          >
            {hasCancelButton && hasSecondaryButton && (
              <Button
                data-dd-action={onCancelDDActionName}
                disabled={confirmRequesting}
                label={cancelText}
                variant={cancelButtonVariant}
                onClick={() => {
                  onCancelButtonClick?.();
                  !skipCloseModal && close?.();
                }}
              />
            )}
            <Stack
              direction={isDesktop ? "row" : "column-reverse"}
              spacing="xs3"
            >
              {hasCancelButton && !hasSecondaryButton && (
                <Button
                  disabled={confirmRequesting}
                  label={cancelText}
                  variant={cancelButtonVariant}
                  onClick={() => {
                    onCancelButtonClick?.();
                    !skipCloseModal && close?.();
                  }}
                />
              )}

              {hasSecondaryButton && (
                <Button
                  label={secondaryButtonText}
                  variant={secondaryButtonVariant}
                  onClick={() => {
                    onSecondaryButtonClick?.();
                  }}
                />
              )}

              <Button
                Icon={confirmButtonIcon}
                data-dd-action={onConfirmDDActionName}
                data-test-id={`popup-button-${buttonText}`}
                disabled={confirmDisabled}
                iconPlacement={confirmButtonIconPlacement}
                label={buttonText}
                loading={confirmRequesting}
                variant={confirmButtonVariant}
                onClick={(e) => {
                  onConfirmButtonClick?.(e);
                  if (!skipCloseAndConfirm) {
                    confirmAndClose?.(e);
                  }
                }}
              />
            </Stack>
          </Styled.Actions>
        </Styled.ModalWrapper>
      </Modal>
    );
  }
);
