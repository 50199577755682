import { ClientEnv } from 'constants/Chat.constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectInit, resetChat } from 'actions';
import { TurnMetadata } from 'api/resources/conversation/types';
import { ChatMessage } from 'api/types';

export interface ChatState {
  conversationIds: Record<ClientEnv, string | null>;
  messages: Record<ClientEnv, ChatMessage[]>;
  isLoading: Record<ClientEnv, boolean>;
  isEnded: Record<ClientEnv, boolean>;
  isErrored: Record<ClientEnv, boolean>;
  messageDraft: Record<ClientEnv, string>;
  isWaitingSlowResponse: boolean;
  endTurns: Record<ClientEnv, ChatMessage>;
}

const initialState: ChatState = {
  conversationIds: {
    draft: null,
    sandbox: null,
    'pre-release': null,
    live: null,
  },
  messages: {
    draft: [],
    sandbox: [],
    'pre-release': [],
    live: [],
  },
  isLoading: {
    draft: false,
    sandbox: false,
    'pre-release': false,
    live: false,
  },
  isEnded: {
    draft: false,
    sandbox: false,
    'pre-release': false,
    live: false,
  },
  isErrored: {
    draft: false,
    sandbox: false,
    'pre-release': false,
    live: false,
  },
  messageDraft: {
    draft: '',
    sandbox: '',
    'pre-release': '',
    live: '',
  },
  isWaitingSlowResponse: false,
  endTurns: {
    draft: {
      text: '',
      from: 'agent',
      metadata: {
        function_events: [],
      },
    },
    sandbox: {
      text: '',
      from: 'agent',
      metadata: {
        function_events: [],
      },
    },
    'pre-release': {
      text: '',
      from: 'agent',
      metadata: {
        function_events: [],
      },
    },
    live: {
      text: '',
      from: 'agent',
      metadata: {
        function_events: [],
      },
    },
  },
};

type ClientEnvPayload = {
  clientEnv: ClientEnv;
};

const testAgentSlice = createSlice({
  name: 'testAgent',
  initialState,
  reducers: {
    setConversationId: (
      state,
      {
        payload: { clientEnv, conversationId },
      }: PayloadAction<{ conversationId: string } & ClientEnvPayload>,
    ) => {
      state.conversationIds = {
        ...state.conversationIds,
        [clientEnv]: conversationId,
      };
    },
    setIsLoading: (
      state,
      {
        payload: { clientEnv, isLoading },
      }: PayloadAction<{ isLoading: boolean } & ClientEnvPayload>,
    ) => {
      state.isLoading = { ...state.isLoading, [clientEnv]: isLoading };
    },
    addUserMessage: (
      state,
      {
        payload: { clientEnv, message },
      }: PayloadAction<{ message: ChatMessage } & ClientEnvPayload>,
    ) => {
      state.messages[clientEnv].push(message);
    },
    addAgentResponse: (
      state,
      {
        payload: { clientEnv, agentMessage, previousMessageMetadata },
      }: PayloadAction<
        {
          agentMessage: ChatMessage;
          previousMessageMetadata: TurnMetadata;
        } & ClientEnvPayload
      >,
    ) => {
      const previousMessage = state.messages[clientEnv].at(-1);
      if (previousMessage) {
        previousMessage.metadata = previousMessageMetadata;
      }
      state.messages[clientEnv].push(agentMessage);
    },
    setIsEnded: (
      state,
      {
        payload: { clientEnv, isEnded },
      }: PayloadAction<{ isEnded: boolean } & ClientEnvPayload>,
    ) => {
      state.isEnded = { ...state.isEnded, [clientEnv]: isEnded };
    },
    setIsErrored: (
      state,
      {
        payload: { clientEnv, isErrored },
      }: PayloadAction<{ isErrored: boolean } & ClientEnvPayload>,
    ) => {
      state.isErrored = { ...state.isErrored, [clientEnv]: isErrored };
    },
    setChatMessageDraft: (
      state,
      {
        payload: { clientEnv, message },
      }: PayloadAction<{ message: string } & ClientEnvPayload>,
    ) => {
      state.messageDraft = { ...state.messageDraft, [clientEnv]: message };
    },
    setIsWaitingSlowResponse: (state, { payload }: PayloadAction<boolean>) => {
      state.isWaitingSlowResponse = payload;
    },
    setEndTurns: (
      state,
      {
        payload,
      }: PayloadAction<{
        clientEnv: ClientEnv;
        from: string;
        message: string;
        metadata: TurnMetadata;
      }>,
    ) => {
      state.endTurns[payload.clientEnv] = {
        text: payload.message,
        from: 'agent',
        metadata: payload.metadata,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetChat, (state, { payload: clientEnv }) => {
        state.conversationIds[clientEnv] = null;
        state.messages[clientEnv] = [];
        state.isErrored[clientEnv] = false;
        state.isEnded[clientEnv] = false;
        state.messageDraft[clientEnv] = '';
        state.endTurns[clientEnv] = {
          text: '',
          from: 'agent',
        };
      })
      .addCase(projectInit, () => {
        return { ...initialState };
      });
  },
});

export const {
  addAgentResponse,
  addUserMessage,
  setIsEnded,
  setIsErrored,
  setConversationId,
  setIsLoading,
  setChatMessageDraft,
  setIsWaitingSlowResponse,
  setEndTurns,
} = testAgentSlice.actions;

export default testAgentSlice.reducer;
