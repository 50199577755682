import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const StepIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11 6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2h3a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3Zm2 0v2h6V6h-6Zm8 2a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2H8v18h16V8h-3Z" />
      </svg>
    </BaseIcon>
  );
};
