import { ButtonHTMLAttributes, FC } from "react";

import { useListItem } from "@floating-ui/react";

import { usePopoverContext } from "./Provider";

import * as Styled from "./Popover.styled";

type PopoverListItemProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  closeOnClick?: boolean;
};

export const PopoverListItem: FC<PopoverListItemProps> = ({
  children,
  closeOnClick,
  ...restProps
}) => {
  const popoverContext = usePopoverContext();
  const {
    getItemProps,
    listContext: { activeIndex, selectedIndex, handleSelect },
    setOpen,
  } = popoverContext;

  const { ref, index } = useListItem();

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <Styled.PopoverListItem
      {...restProps}
      ref={ref}
      aria-selected={isActive && isSelected}
      role="option"
      tabIndex={isActive ? 0 : -1}
      {...getItemProps({
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          handleSelect(index);
          restProps.onClick?.(event);
          if (closeOnClick) {
            setOpen(false);
          }
        },
      })}
    >
      {children}
    </Styled.PopoverListItem>
  );
};
