import React, {
  ComponentPropsWithRef as ComponentProps,
  forwardRef,
  memo,
  useMemo,
} from "react";

import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import Clickable from "../Clickable";
import { BaseIcon } from "../Icons/BaseIcon";

import * as Styled from "./Button.styled";

export enum ButtonVariant {
  BRAND = "brand",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  GHOST = "ghost",
  SUCCESS = "success",
  DANGER = "danger",
  MAKE_IT_POP = "make-it-pop",
}

export type ButtonLabelSize = "small" | "regular";

export interface ButtonProps extends ComponentProps<"button"> {
  label: string;
  variant?: keyof typeof ButtonVariant;
  inverse?: boolean;
  Icon?: typeof BaseIcon;
  LeftIcon?: typeof BaseIcon;
  RightIcon?: typeof BaseIcon;
  iconPlacement?: "left" | "right" | "both";
  iconOnly?: boolean;
  size?: ButtonLabelSize;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  to?: string;
}

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        label,
        variant = "PRIMARY",
        inverse = false,
        Icon,
        LeftIcon,
        RightIcon,
        iconPlacement = "left",
        iconOnly,
        size = "regular",
        loading,
        disabled,
        fullWidth,
        to,
        ...props
      },
      ref
    ) => {
      const showLeftIcon = useMemo(
        () => iconPlacement && ["left", "both"].includes(iconPlacement),
        [iconPlacement]
      );
      const showRightIcon = useMemo(
        () => iconPlacement && ["right", "both"].includes(iconPlacement),
        [iconPlacement]
      );

      const iconColor = useMemo(() => {
        if (disabled && !inverse) {
          return "iconDisabled";
        } else if (disabled && inverse) {
          return "iconInverseDisabled";
        } else if (inverse) {
          return Styled.variantToInverseButtonIconColor[variant];
        }

        return Styled.variantToButtonIconColor[variant];
      }, [disabled, inverse, variant]);

      /* The new version of the deprecated IconButton */
      if (Icon && iconOnly) {
        return (
          <Styled.IconOnlyButtonWrapper
            ref={ref}
            $inverse={inverse}
            $loading={loading}
            $size={size}
            $variant={variant}
            aria-disabled={loading || disabled}
            aria-label={loading ? "Loading" : label}
            disabled={disabled}
            {...props}
          >
            <Styled.ButtonContentContainer
              $show={loading ? false : true}
              $size="small"
            >
              <Icon color={iconColor} />
            </Styled.ButtonContentContainer>
            <Styled.LoadingIcon $show={!!loading} />
          </Styled.IconOnlyButtonWrapper>
        );
      }

      return (
        <ConditionalWrapper
          condition={!!to}
          wrapper={(children) => <Clickable to={to}>{children}</Clickable>}
        >
          <Styled.ButtonWrapper
            ref={ref}
            $fullWidth={fullWidth}
            $inverse={inverse}
            $loading={loading}
            $size={size}
            $variant={variant}
            disabled={disabled}
            {...props}
          >
            <Styled.ButtonContentContainer
              $show={loading ? false : true}
              $size={size}
            >
              {(Icon || LeftIcon) &&
                showLeftIcon &&
                (LeftIcon ? (
                  <LeftIcon color={iconColor} />
                ) : Icon ? (
                  <Icon color={iconColor} />
                ) : null)}

              <Styled.ButtonLabel
                $disabled={disabled}
                $hasIcon={!!Icon}
                $size={size}
              >
                {label}
              </Styled.ButtonLabel>

              {(Icon || RightIcon) &&
                showRightIcon &&
                (RightIcon ? (
                  <RightIcon color={iconColor} />
                ) : Icon ? (
                  <Icon color={iconColor} />
                ) : null)}
            </Styled.ButtonContentContainer>
            <Styled.LoadingIcon $show={loading ? true : false} />
          </Styled.ButtonWrapper>
        </ConditionalWrapper>
      );
    }
  )
);

export default Button;
