import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Clickable from '@polyai/ui/components/atoms/Clickable';
import IconButton from '@polyai/ui/components/atoms/IconButton';
import BaseNavSection from '@polyai/ui/components/molecules/NavSection';
import { NavSectionHeader } from '@polyai/ui/components/molecules/NavSection/NavSection.styled';
import { NavSectionItemContainer } from '@polyai/ui/components/molecules/NavSectionItem/NavSectionItem.styled';
import themeColors from '@polyai/ui/styles/config/colors';
import sizes from '@polyai/ui/styles/config/sizes';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import hexToRgb from '@polyai/ui/styles/functions/hexToRgb';

export const Overlay = styled.div<{ $open: boolean }>`
  pointer-events: none;

  position: fixed;
  z-index: 24;
  inset: 0 0 0 100%;

  background-color: transparent;

  transition: background-color 0.2s ease-in-out;

  ${({ $open }) =>
    $open &&
    css`
      pointer-events: all;
      left: 0;
      background-color: ${themeColors.backgroundOverlay};
    `}
`;

export const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: available;
  width: fill-available;
  width: ${sizes.mainNavWidth}px;
  height: 100vh;
  height: 100dvh;

  color: ${themeColors.textSecondaryInverse};

  background-color: ${themeColors.backgroundInverse};
`;

export const NavMain = styled.div`
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Scrollable = styled.div<{
  $navBottomHeight?: number;
  $backButtonHeight?: number;
}>`
  position: relative;
  overflow: hidden;
  flex: 0 1
    calc(
      100dvh -
        ${({ $navBottomHeight = 0, $backButtonHeight = 0 }) =>
          sizes.headerHeight + $navBottomHeight + $backButtonHeight}px
    );
`;

export const ScrollFader = styled(motion.div)<{ position: 'top' | 'bottom' }>`
  ${({ position }) => (position === 'top' ? 'top' : 'bottom')}: 0;

  pointer-events: none;

  display: flex;
  justify-content: center;
  ${({ position }) =>
    position === 'top'
      ? css`
          padding-top: ${themeSpacing.xs1};
        `
      : css`
          align-items: flex-end;
          padding-bottom: ${themeSpacing.xs1};
        `};

  position: absolute;
  z-index: 11;

  overflow: hidden;

  height: ${themeSpacing.md1};
  width: 100%;

  background: linear-gradient(
    ${({ position, theme }) =>
        hexToRgb(
          theme.activeColorScheme.colors.backgroundInverse,
          position === 'top' ? 1 : 0,
        )}
      ${({ position }) => (position === 'top' ? '50%' : '0%')},
    ${({ position, theme }) =>
        hexToRgb(
          theme.activeColorScheme.colors.backgroundInverse,
          position === 'bottom' ? 1 : 0,
        )}
      ${({ position }) => (position === 'top' ? '100%' : '50%')}
  );
`;

export const NavMainInner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: inherit;
`;

export const NavMinutes = styled.div`
  display: flex;
  flex: 0 0;
  flex-direction: column;
  justify-content: flex-start;

  padding: ${themeSpacing.sm1};
`;

export const NavHeader = styled.div`
  display: flex;
  flex: 0 0 ${sizes.headerHeight}px;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${themeSpacing.sm3};

  border-bottom: 1px solid ${themeColors.borderSubtleInverse};
`;

export const BackToAssistantsNavSection = styled(Clickable)`
  display: flex;
  gap: ${themeSpacing.sm1};
  align-items: center;

  padding: ${themeSpacing.xs2} ${themeSpacing.sm3};

  border-bottom: 1px solid ${themeColors.borderSubtleInverse};
`;

export const NavSection = styled(BaseNavSection)`
  button {
    border-top: unset;
    border-bottom: unset !important;
  }

  &:not(:first-child) {
    button {
      border-top: 1px solid ${themeColors.borderSubtleInverse} !important;
    }
  }

  ${NavSectionHeader} {
    height: auto;
    padding: ${themeSpacing.sm2} ${themeSpacing.sm3};
  }

  ${NavSectionItemContainer}:first-child {
    padding-top: 0;
  }
`;

export const LogoWrapper = styled.div`
  color: ${themeColors.iconPrimaryInverse};
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  &:active {
    outline: none;

    path {
      fill: ${themeColors.backgroundBrand} !important;
    }
  }
`;

export const MenuButton = styled(IconButton)`
  z-index: 20;
`;

export const NavDocumentation = styled.div`
  padding: ${themeSpacing.sm3} ${themeSpacing.sm4};
  list-style: none;
  border-top: 1px solid ${themeColors.borderSubtleInverse};

  li {
    padding-top: 0;
  }
`;
