import styled, { css } from 'styled-components';

import Badge from '@polyai/ui/components/atoms/Badge';
import Clickable from '@polyai/ui/components/atoms/Clickable';
import Skeleton from '@polyai/ui/components/atoms/Skeleton';
import {
  Body,
  BodyStrong,
  Caption,
  Subtitle,
} from '@polyai/ui/components/atoms/Text';
import themeColors, {
  baseColors,
  ColorName,
} from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text, { Text } from '@polyai/ui/styles/config/text';

export type ColWidth = 'icon' | 'small' | 'medium' | 'large';
export type TextColor = 'textHelper' | 'textPrimary' | 'textSecondary';
export type FontSize = 'sm' | 'md' | 'lg';
export type FontWeight = keyof Text['weight'];
export const CARD_COLUMN_TOOLTIP_WIDTH = 300;
export const CARD_COLUMN_TITLE_MAX_WIDTH = 165;

interface ContainerProps {
  $disabled?: boolean;
  $mobile?: boolean;
  $cols: ColWidth[];
  to?: string;
  $fullWidth?: boolean;
  $loading?: boolean;
  $secondary?: boolean;
  $borderColor?: ColorName;
}

export const Container = styled(Clickable)<ContainerProps>`
  display: grid;
  grid-column-gap: ${themeSpacing.sm2};
  grid-template-columns: ${({ $cols }) =>
    $cols
      .map((col) => {
        switch (col) {
          case 'icon':
            return '20px';
          case 'medium':
            return '2fr';
          case 'large':
            return '3fr';
          default:
            return '1fr';
        }
      })
      .join(' ')};
  ${({ $fullWidth = false }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};
  align-items: center;
  align-self: stretch;
  justify-content: space-between;

  padding: ${themeSpacing.sm3} ${themeSpacing.sm4};

  background: ${({ $secondary }) =>
    $secondary ? baseColors.offWhite : themeColors.background};
  border-radius: ${themeSpacing.radiusMedium};

  ${({ to }) =>
    !to &&
    css`
      cursor: auto;
    `}

  ${({ to, onClick }) =>
    (!!to || !!onClick) &&
    css`
      cursor: pointer;
    `}

  ${({ $mobile }) =>
    $mobile &&
    css`
      padding: ${themeSpacing.sm1} ${themeSpacing.sm1};
    `}

  :hover {
    background: ${themeColors.backgroundHover};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      background: ${baseColors.offWhite};
    `}
  
    ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${themeColors[$borderColor]};
    `}
`;

const ellipsisOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CardContent = styled.div`
  grid-column: span 1;
`;

export const CardContainer = styled(CardContent)`
  overflow: hidden;
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;

  > * {
    ${ellipsisOverflow}
  }
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xxs2};
  align-items: flex-start;
`;

export const IconBadge = styled(Badge)`
  flex-shrink: 0;
  padding: ${themeSpacing.xs1};
  border-radius: ${themeSpacing.radiusFull};
`;

export const TooltipWrapper = styled.div<{ $underline?: boolean }>`
  ${({ $underline }) =>
    $underline &&
    css`
      border-bottom: 1px dashed ${themeColors.textHelper};
    `};
`;

export const CardValue = styled(BodyStrong)`
  ${ellipsisOverflow}
  width: auto;
  text-align: left;
`;

export const CardValueMissing = styled(CardValue)`
  color: ${themeColors.textHelper};
`;

export const CardCaption = styled(Caption)`
  ${ellipsisOverflow}
  width: 100%;
  text-align: left;
`;

export const ValueCaption = styled(Caption)`
  color: ${themeColors.textSecondary};
  text-align: start;
`;

export const PrimaryCaption = styled(BodyStrong)<{ $width?: number }>`
  ${ellipsisOverflow}
  width: ${({ $width }) => $width}px;

  font-size: ${text.size.body.body3};
  font-weight: ${text.weight.semibold};
  color: ${themeColors.textPrimary};
  text-align: start;
`;

export const Name = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: ${themeSpacing.xxs2};
  align-items: center;

  max-width: 100%;
  height: auto;
`;

export const NameContainer = styled(CardContent)`
  overflow: hidden;
  max-width: 100%;
`;

export const NameSkeletonLoader = styled(Skeleton)`
  width: 80%;
`;

export const EditedSkeletonLoader = styled(Skeleton)`
  width: 60%;
`;

export const JupiterCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xs2};
  margin: ${themeSpacing.sm3} 0;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${themeSpacing.xs2};
  align-items: center;

  padding: ${themeSpacing.xs3};

  text-align: center;
`;

export const LoadingMessage = styled(Body)`
  font-size: ${text.size.body.body4};
  color: ${themeColors.textHelper};
`;

export const NameText = styled(Subtitle)<{ $fontSize?: FontSize }>`
  ${ellipsisOverflow}
  ${({ $fontSize = 'lg' }) => {
    switch ($fontSize) {
      case 'sm':
        return text.size.body.body3;
      case 'md':
        return text.size.body.body1;
      case 'lg':
        return text.size.heading.h2;
    }
  }}
`;

export const OverflowContent = styled.span`
  text-overflow: clip;
`;

export const SkeletonContainer = styled(BodyStrong)`
  width: 100%;
`;
