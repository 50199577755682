import { ClientEnv } from 'constants/Chat.constants';

import { doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { EndResponse, RespondBody, RespondResponse } from './types';

export const startChat = (
  accountId: string,
  projectId: string,
  clientEnv: string,
  languageCode: string,
) => {
  return doPost<RespondResponse>(
    `accounts/${accountId}/projects/${projectId}/chat`,
    LOGGER_TYPE.JUPITER,
    {
      client_env: clientEnv,
      asr_lang_code: languageCode,
      tts_lang_code: languageCode,
    },
    { version: 'v2' },
  );
};

export const respond = (
  accountId: string,
  projectId: string,
  conversationId: string,
  clientEnv: string,
  body: RespondBody,
) => {
  return doPost<RespondResponse>(
    `accounts/${accountId}/projects/${projectId}/chat/${conversationId}`,
    LOGGER_TYPE.JUPITER,
    { ...body, client_env: clientEnv },
    { version: 'v2' },
  );
};

export const startDraftChat = (
  accountId: string,
  projectId: string,
  languageCode: string,
) => {
  return doPost<RespondResponse>(
    `accounts/${accountId}/projects/${projectId}/draft/chat`,
    LOGGER_TYPE.JUPITER,
    {
      asr_lang_code: languageCode,
      tts_lang_code: languageCode,
    },
    { version: 'v2' },
  );
};

export const draftRespond = (
  accountId: string,
  projectId: string,
  conversationId: string,
  body: RespondBody,
) => {
  return doPost<RespondResponse>(
    `accounts/${accountId}/projects/${projectId}/draft/chat/${conversationId}`,
    LOGGER_TYPE.JUPITER,
    body,
    { version: 'v2' },
  );
};

export const endChat = (
  accountId: string,
  projectId: string,
  conversationId: string,
  clientEnv: ClientEnv,
) => {
  const path = `accounts/${accountId}/projects/${projectId}/chat/${conversationId}/end`;
  return doPost<EndResponse>(
    path,
    LOGGER_TYPE.JUPITER,
    {
      client_env: clientEnv,
    },
    { version: 'v2' },
  );
};
