import rem from "styles/functions/rem";

export interface Sizes {
    constantsColumnWidth: string;
    headerHeight: number;
    actionHeaderHeight: number;
    mainNavWidth: number;
    maxNavTextWidth: number;
    issueReporterWidth: number;
    panelWidth: number;
    panelMenuWidth: number;
    confirmationModalWidth: number;
    alertWidth: number;
    inputHeight: string;
    dateFilterInputWidth: string;
    modalWidthSmall: string;
    modalWidthMedium: string;
    modalWidthLarge: string;
    modalWidthExtraLarge: string;
    gridContentWidth: string;
    gridSidebarWidth: number;
}

const sizes: Sizes = {
    constantsColumnWidth: rem(200),
    headerHeight: 70,
    actionHeaderHeight: 56,
    mainNavWidth: 240,
    maxNavTextWidth: 156,
    issueReporterWidth: 900,
    panelWidth: 415,
    panelMenuWidth: 300,
    confirmationModalWidth: 300,
    alertWidth: 350,
    inputHeight: rem(45),
    dateFilterInputWidth: rem(100),
    modalWidthSmall: rem(360),
    modalWidthMedium: rem(560),
    modalWidthLarge: rem(800),
    modalWidthExtraLarge: rem(1080),
    gridContentWidth: rem(960),
    gridSidebarWidth: 415,
};

export const getHeaderOffset = ({
    $isHeaderExtended,
}: {
    $isHeaderExtended?: boolean;
}) => sizes.headerHeight + ($isHeaderExtended ? sizes.actionHeaderHeight : 0);

export default sizes;
