const ACCOUNTS = `accounts`;
const ACCOUNT = `${ACCOUNTS}/:accountId`;

const PROJECTS = `${ACCOUNT}/projects`;
const PROJECTS_METRICS = `${PROJECTS}/conversation-metrics`;
const PROJECT = `${PROJECTS}/:projectId`;
const PUBLISH = `${PROJECT}/deployments/publish`;
const DRAFT = `${PROJECT}/draft`;

const PROJECT_FEATURES = `${PROJECT}/features`;
const DUPLICATE_PROJECT = `${PROJECT}/duplicate`;
const EXPORT_PROJECT = `${PROJECT}/export`;
const IMPORT_PROJECT = `${PROJECTS}/import`;

const HANDOFFS = `${PROJECT}/handoffs`;
const HANDOFF = `${HANDOFFS}/:handoffId`;
const DEFAULT_HANDOFF = `${HANDOFFS}/default`;

const FUNCTIONS = `${PROJECT}/functions`;
const DEPLOY_FUNCTIONS = `${FUNCTIONS}/deploy`;
const FUNCTION_CUSTOM_LIB = `${FUNCTIONS}/custom_lib`;

const FUNCTION = `${FUNCTIONS}/:functionId`;
const DUPLICATE_FUNCTION = `${FUNCTION}/duplicate`;
const EXECUTE_FUNCTION = `${FUNCTION}/execute`;

const KNOWLEDGE_BASE = `${PROJECT}/knowledge-base`;
const DISCLAIMER = `${PROJECT}/disclaimer`;
const TOPICS = `${KNOWLEDGE_BASE}/topics`;
const TOPICS_IMPORT = `${TOPICS}/import`;
const TOPICS_EXPORT = `${TOPICS}/export`;
const TOPIC = `${TOPICS}/:topicId`;

const CONFIG = `${PROJECT}/config`;
const VOICE = `${PROJECT}/voice`;
const MODEL = `${PROJECT}/model`;

const SAMPLE_PROJECTS = `sample-projects`;
const SAMPLE_PROJECT = `${SAMPLE_PROJECTS}/:projectId`;
const DUPLICATE_SAMPLE_PROJECT = `${SAMPLE_PROJECT}/duplicate`;

const VOICE_TUNING_SETTINGS = `${PROJECT}/voices/:voiceId`;
const VOICE_TUNING_TTS = `${PROJECT}/generate-audio`;

const SMS_TEMPLATES = `${PROJECT}/sms-templates`;
const SMS_TEMPLATE = `${PROJECT}/sms-templates/:smsTemplateId`;
const SMS_CREDENTIALS = `${PROJECT}/sms-credentials/:integration`;

const PRONUNCIATIONS = `${PROJECT}/pronunciations`;

const USER_MANAGEMENT = `users`;

const LANGUAGES = `languages`;

const FLOWS = `${PROJECT}/flows`;
const FLOW = `${PROJECT}/flows/:flowId`;
const DUPLICATE_FLOW = `${FLOW}/duplicate`;

const DEPLOYMENTS = `${PROJECT}/deployments`;
const PROMOTE_DEPLOYMENT = `${DEPLOYMENTS}/:deploymentId/promote`;
const ROLLBACK_DEPLOYMENT = `${DEPLOYMENTS}/:deploymentId/rollback`;
const ACTIVE_DEPLOYMENTS = `${DEPLOYMENTS}/active`;
const DEPLOYMENT_STATE = `${DEPLOYMENTS}/:deploymentId/state`;

const PHONE_NUMBERS = `${PROJECT}/numbers`;

const RESOURCE_METRICS = `${PROJECT}/resource-metrics`;
const SMS_TEMPLATES_USAGE_METRICS = `${RESOURCE_METRICS}/sms-templates`;

const STOP_KEYWORDS = `${PROJECT}/stop-keywords`;
const STOP_KEYWORD = `${STOP_KEYWORDS}/:stopKeywordId`;

const VARIANT_MANAGEMENT = `${PROJECT}/variant-management`;
const VARIANT_MANAGEMENT_VALUES = `${VARIANT_MANAGEMENT}/values`;

const VARIANTS = `${VARIANT_MANAGEMENT}/variants`;
const VARIANT = `${VARIANTS}/:variantId`;

const AUDIO_CACHE = `${PROJECT}/audio-cache`;
const AUDIO_CACHE_ENTRY = `${AUDIO_CACHE}/:entryId`;
const AUDIO_CACHE_DETAILS = `${AUDIO_CACHE_ENTRY}/details`;
const AUDIO_CACHE_AUDIO_FILE = `${AUDIO_CACHE_ENTRY}/file`;
const AUDIO_CACHE_ENTRY_SYNTHESIZE = `${AUDIO_CACHE_ENTRY}/synthesize`;

const ATTRIBUTES = `${VARIANT_MANAGEMENT}/attributes`;
const ATTRIBUTE = `${ATTRIBUTES}/:attributeId`;
const ASR_ANNOTATIONS_TASKS = `asr_annotations/tasks`;
const ASR_ANNOTATIONS_TASK_ID = `${ASR_ANNOTATIONS_TASKS}/:taskId`;
const ASR_ANNOTATIONS_ID = `${ASR_ANNOTATIONS_TASK_ID}/annotations/:annotationId`;

const TRANSCRIPT_CORRECTIONS = `${PROJECT}/asr-corrections`;

const FUNCTIONS_LIB = `functions-lib`;

// This is necessary to allow typescript to infer the necessary parameters
// for each EP
type EPS = {
  ACCOUNTS: typeof ACCOUNTS;
  ACCOUNT: typeof ACCOUNT;

  PROJECTS: typeof PROJECTS;
  PROJECTS_METRICS: typeof PROJECTS_METRICS;
  PROJECT: typeof PROJECT;
  PUBLISH: typeof PUBLISH;
  DRAFT: typeof DRAFT;
  DUPLICATE_PROJECT: typeof DUPLICATE_PROJECT;
  IMPORT_PROJECT: typeof IMPORT_PROJECT;
  EXPORT_PROJECT: typeof EXPORT_PROJECT;
  PROJECT_FEATURES: typeof PROJECT_FEATURES;
  KNOWLEDGE_BASE: typeof KNOWLEDGE_BASE;
  DISCLAIMER: typeof DISCLAIMER;

  HANDOFFS: typeof HANDOFFS;
  HANDOFF: typeof HANDOFF;
  DEFAULT_HANDOFF: typeof DEFAULT_HANDOFF;

  FUNCTIONS: typeof FUNCTIONS;
  DEPLOY_FUNCTIONS: typeof DEPLOY_FUNCTIONS;
  FUNCTION: typeof FUNCTION;
  DUPLICATE_FUNCTION: typeof DUPLICATE_FUNCTION;
  EXECUTE_FUNCTION: typeof EXECUTE_FUNCTION;
  FUNCTION_CUSTOM_LIB: typeof FUNCTION_CUSTOM_LIB;

  TOPICS: typeof TOPICS;
  TOPICS_IMPORT: typeof TOPICS_IMPORT;
  TOPICS_EXPORT: typeof TOPICS_EXPORT;
  TOPIC: typeof TOPIC;

  CONFIG: typeof CONFIG;
  VOICE: typeof VOICE;
  MODEL: typeof MODEL;

  SAMPLE_PROJECTS: typeof SAMPLE_PROJECTS;
  SAMPLE_PROJECT: typeof SAMPLE_PROJECT;
  DUPLICATE_SAMPLE_PROJECT: typeof DUPLICATE_SAMPLE_PROJECT;

  VOICE_TUNING_SETTINGS: typeof VOICE_TUNING_SETTINGS;
  VOICE_TUNING_TTS: typeof VOICE_TUNING_TTS;

  SMS_TEMPLATES: typeof SMS_TEMPLATES;
  SMS_TEMPLATE: typeof SMS_TEMPLATE;
  SMS_CREDENTIALS: typeof SMS_CREDENTIALS;

  PRONUNCIATIONS: typeof PRONUNCIATIONS;

  USER_MANAGEMENT: typeof USER_MANAGEMENT;

  LANGUAGES: typeof LANGUAGES;

  FLOWS: typeof FLOWS;
  FLOW: typeof FLOW;
  DUPLICATE_FLOW: typeof DUPLICATE_FLOW;

  DEPLOYMENTS: typeof DEPLOYMENTS;
  PROMOTE_DEPLOYMENT: typeof PROMOTE_DEPLOYMENT;
  ROLLBACK_DEPLOYMENT: typeof ROLLBACK_DEPLOYMENT;
  ACTIVE_DEPLOYMENTS: typeof ACTIVE_DEPLOYMENTS;
  DEPLOYMENT_STATE: typeof DEPLOYMENT_STATE;

  PHONE_NUMBERS: typeof PHONE_NUMBERS;

  SMS_TEMPLATES_USAGE_METRICS: typeof SMS_TEMPLATES_USAGE_METRICS;

  STOP_KEYWORDS: typeof STOP_KEYWORDS;
  STOP_KEYWORD: typeof STOP_KEYWORD;

  VARIANT_MANAGEMENT: typeof VARIANT_MANAGEMENT;
  VARIANT_MANAGEMENT_VALUES: typeof VARIANT_MANAGEMENT_VALUES;

  VARIANTS: typeof VARIANTS;
  VARIANT: typeof VARIANT;

  AUDIO_CACHE: typeof AUDIO_CACHE;
  AUDIO_CACHE_ENTRY: typeof AUDIO_CACHE_ENTRY;
  AUDIO_CACHE_DETAILS: typeof AUDIO_CACHE_DETAILS;
  AUDIO_CACHE_AUDIO_FILE: typeof AUDIO_CACHE_AUDIO_FILE;
  AUDIO_CACHE_ENTRY_SYNTHESIZE: typeof AUDIO_CACHE_ENTRY_SYNTHESIZE;

  ATTRIBUTES: typeof ATTRIBUTES;
  ATTRIBUTE: typeof ATTRIBUTE;
  ASR_ANNOTATIONS_TASKS: typeof ASR_ANNOTATIONS_TASKS;
  ASR_ANNOTATIONS_TASK_ID: typeof ASR_ANNOTATIONS_TASK_ID;
  ASR_ANNOTATIONS_ID: typeof ASR_ANNOTATIONS_ID;

  TRANSCRIPT_CORRECTIONS: typeof TRANSCRIPT_CORRECTIONS;

  FUNCTIONS_LIB: typeof FUNCTIONS_LIB;
};

const ENDPOINTS: EPS = {
  ACCOUNTS,
  ACCOUNT,

  PROJECTS,
  PROJECTS_METRICS: PROJECTS_METRICS,
  PROJECT,
  PUBLISH,
  DRAFT,

  DUPLICATE_PROJECT,
  IMPORT_PROJECT,
  EXPORT_PROJECT,
  PROJECT_FEATURES,

  HANDOFFS,
  HANDOFF,
  DEFAULT_HANDOFF,

  FUNCTIONS,
  DEPLOY_FUNCTIONS,
  FUNCTION,
  DUPLICATE_FUNCTION,
  EXECUTE_FUNCTION,
  FUNCTION_CUSTOM_LIB,

  KNOWLEDGE_BASE,
  DISCLAIMER,
  TOPICS,
  TOPICS_IMPORT,
  TOPICS_EXPORT,
  TOPIC,

  CONFIG,
  VOICE,
  MODEL,

  SAMPLE_PROJECTS,
  SAMPLE_PROJECT,
  DUPLICATE_SAMPLE_PROJECT,

  VOICE_TUNING_SETTINGS,
  VOICE_TUNING_TTS,

  SMS_TEMPLATES,
  SMS_TEMPLATE,
  SMS_CREDENTIALS,

  PRONUNCIATIONS,

  USER_MANAGEMENT,

  LANGUAGES,

  FLOWS,
  FLOW,
  DUPLICATE_FLOW,

  DEPLOYMENTS,
  PROMOTE_DEPLOYMENT,
  ROLLBACK_DEPLOYMENT,
  ACTIVE_DEPLOYMENTS,
  DEPLOYMENT_STATE,

  PHONE_NUMBERS,

  SMS_TEMPLATES_USAGE_METRICS,

  STOP_KEYWORDS,
  STOP_KEYWORD,

  TRANSCRIPT_CORRECTIONS,

  VARIANT_MANAGEMENT,
  VARIANT_MANAGEMENT_VALUES,

  VARIANTS,
  VARIANT,

  AUDIO_CACHE,
  AUDIO_CACHE_DETAILS,
  AUDIO_CACHE_ENTRY,
  AUDIO_CACHE_AUDIO_FILE,
  AUDIO_CACHE_ENTRY_SYNTHESIZE,

  ATTRIBUTES,
  ATTRIBUTE,
  ASR_ANNOTATIONS_TASKS,
  ASR_ANNOTATIONS_TASK_ID,
  ASR_ANNOTATIONS_ID,

  FUNCTIONS_LIB,
};

export const CALL_WS_ENDPOINT = `${process.env.NEXT_PUBLIC_POLYAI_BIDIRECTIONAL_MEDIA_WS}/api/v1/conversation`;

export default ENDPOINTS;
