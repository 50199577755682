import {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useTheme } from "styled-components";

import { Breakpoints } from "styles/config/breakpoints";

type BreakpointContextType = React.RefObject<HTMLDivElement> | null;

const BreakpointContext = createContext<BreakpointContextType>(null);

export const BreakpointContainer = ({ children }: PropsWithChildren) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <BreakpointContext.Provider value={ref}>
      <div ref={ref}>{children}</div>
    </BreakpointContext.Provider>
  );
};

export const useBreakpoint = (breakpoint: keyof Breakpoints) => {
  const theme = useTheme();
  const [screenWidth, setScreenWidth] = useState<number | undefined>(undefined);
  const containerRef = useContext(BreakpointContext);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(containerRef?.current?.clientWidth ?? window.innerWidth);
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    const currentRef = containerRef?.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    } else {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      } else {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [containerRef]);

  return useMemo(() => {
    if (screenWidth) {
      return theme.breakpoints[breakpoint] <= screenWidth;
    }
    return false;
  }, [breakpoint, screenWidth, theme.breakpoints]);
};
