import { Placement } from "@floating-ui/react";
import { TransitionStatus } from "react-transition-group";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const TOOLTIP_MAX_WIDTH = 400;
export const DEFAULT_Z_INDEX = 1000;

const ANIMATION_OFFSET = 10;

const getEntryTransform = (placement: Placement) => {
  if (placement.includes("left") || placement.includes("right")) {
    return "translateX";
  }
  return "translateY";
};

const getEntryTransformValue = (placement: Placement) => {
  if (placement.includes("left")) {
    return -ANIMATION_OFFSET;
  } else if (placement.includes("right")) {
    return ANIMATION_OFFSET;
  } else if (placement.includes("bottom")) {
    return ANIMATION_OFFSET;
  }

  return -ANIMATION_OFFSET;
};

export const TooltipWrapper = styled.div<{
  $strategy: React.CSSProperties["position"];
  $x: number | null;
  $y: number | null;
  $placement: Placement;
  $complex?: boolean;
  $disabled?: boolean;
  $state?: TransitionStatus;
  $zIndex?: number;
}>`
  ${({ $state }) =>
    $state !== "entered"
      ? css`
          pointer-events: none;
        `
      : ""}
  position: ${({ $strategy }) => $strategy};
  z-index: ${({ $zIndex }) => $zIndex};
  top: ${({ $y }) => ($y != null ? `${$y}px` : "0")};
  left: ${({ $x }) => ($x != null ? `${$x}px` : "0")};

  max-width: ${TOOLTIP_MAX_WIDTH}px !important;
  min-height: 22px;
  ${({ $complex }) =>
    $complex
      ? css`
          padding: ${themeSpacing.xs3} ${themeSpacing.sm1};
        `
      : css`
          padding: ${themeSpacing.xs1} ${themeSpacing.xs3};
        `}

  color: ${themeColors.textPrimaryInverse};
  white-space: nowrap;

  background: ${themeColors.backgroundInverse};
  border-radius: ${themeSpacing.radiusSmall};

  transition: transform 0.25s ease-in-out, opacity 0.2s ease-in-out;

  > * {
    cursor: ${({ $disabled, $state }) =>
      ($disabled || $state === "exited") && "default"};
  }

  ${({ $state, $placement }) => {
    switch ($state) {
      case "entering":
      case "exited":
        return css`
          transform: ${`${getEntryTransform(
            $placement
          )}(${getEntryTransformValue($placement)}px)`};
          opacity: 0;
        `;
      case "entered":
      case "exiting":
        return css`
          transform: ${`${getEntryTransform($placement)}(0)`};
          opacity: 1;
        `;
    }
  }}
`;

export const TooltipContent = styled.div`
  font-size: ${({ theme }) => theme.text.size.body.body4};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  color: ${themeColors.textPrimaryInverse};
`;

export const TooltipTrigger = styled.span<{
  $disabled?: boolean;
  $inline?: boolean;
  $targetOverflow?: boolean;
}>`
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  display: ${({ $inline }) => ($inline ? "inline-block" : "flex")};
  align-items: center;

  ${({ $targetOverflow }) =>
    $targetOverflow &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;
