import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import { AudioCacheDetails, AudioCachesResponse } from './types';

export const NUM_CACHED_AUDIOS_PER_PAGE = 50;

export const getCachedAudios = ({
  query,
  limit = NUM_CACHED_AUDIOS_PER_PAGE,
}: {
  query: string;
  limit?: number;
}) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<AudioCachesResponse>(
    generateNextPath(ENDPOINTS.AUDIO_CACHE, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      params: {
        limit,
      },
      query,
      version: 'v2',
    },
  );
};

export const getCachedAudioDetails = async ({
  entryId,
}: {
  entryId: string;
}) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<AudioCacheDetails>(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_DETAILS, {
      accountId,
      projectId,
      entryId: entryId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
    },
  );
};

export const getCachedAudioFile = async ({ entryId }: { entryId: string }) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<Blob>(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_AUDIO_FILE, {
      accountId,
      projectId,
      entryId: entryId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      version: 'v2',
      downloadingFile: true,
    },
  );
};

export const updateCachedAudioDetails = async ({
  entryId,
  data,
}: {
  entryId: string;
  data: {
    settings: {
      text: string;
      config?: AudioCacheDetails['voice_tuning_settings'];
    };
    audio: Blob;
  };
}) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  const formData = new FormData();
  formData.append('settings', JSON.stringify(data.settings)); // Convert settings to JSON string
  formData.append('file', data.audio);

  return doPut(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_DETAILS, {
      accountId,
      projectId,
      entryId,
    }),
    LOGGER_TYPE.JUPITER,
    formData,
    {
      postingFile: true,
      version: 'v2',
    },
  );
};

export const deleteCachedAudio = ({ entryId }: { entryId: string }) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doDelete(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_ENTRY, {
      accountId,
      projectId,
      entryId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    {
      version: 'v2',
    },
  );
};

export const synthesizeAudioCache = ({
  data,
  entryId,
}: {
  entryId: string;
  data: {
    text: string;
    config?: AudioCacheDetails['voice_tuning_settings'];
  };
}) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doPost<Blob>(
    generateNextPath(ENDPOINTS.AUDIO_CACHE_ENTRY_SYNTHESIZE, {
      accountId,
      projectId,
      entryId,
    }),
    LOGGER_TYPE.JUPITER,
    data,
    {
      version: 'v2',
      downloadingFile: true,
    },
  );
};
