import { useCallback } from 'react';

import { hasFeatureAccess } from 'lib/featuresHelper';
import { PROJECT_RESOURCE } from './constants';
import { useProjectResources } from './useProjectResources';

export const useProjectResource = () => {
  const { features, restrictedFeatures } = useProjectResources();

  const isProjectResourceEnabled = useCallback(
    (resource: PROJECT_RESOURCE) =>
      hasFeatureAccess(resource, features, restrictedFeatures),
    [features, restrictedFeatures],
  );

  return {
    isProjectResourceEnabled,
  };
};
