import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const StepTransitionIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M6 3a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H6Zm5 2v5H6V5h5Z" />
    <path d="M22.5 8.5H16v-2h6.5a2 2 0 0 1 2 2V15a2 2 0 0 1-2 2h-13v6.5H16v2H9.5a2 2 0 0 1-2-2V17a2 2 0 0 1 2-2h13V8.5Z" />
    <path d="M21.617 20.2c-1.078-.55-2.617.108-2.617 1.53v5.542c0 1.42 1.539 2.078 2.617 1.529l5.431-2.772a1.703 1.703 0 0 0 0-3.056L21.617 20.2Zm4.026 4.3L21 26.869v-4.738l4.643 2.369Z" />
  </BaseIcon>
);
