import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { CustomAnalyticsIntegration } from './types';

export const getCustomIntegration = (projectId: string) => {
  const accountId = store.getState().account.id;

  return doGet<CustomAnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/custom-integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};

export const createCustomIntegration = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<CustomAnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/custom-integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};

export const deleteCustomIntegration = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete<CustomAnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/custom-integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};

export const updateCustomIntegration = (integrationId: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<CustomAnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/custom-integrations`,
    LOGGER_TYPE.ANALYTICS,
    { integration_id: integrationId },
  );
};

export const updateCustomIntegrationConfig = (
  integrationId: string,
  filterByProject?: boolean,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<CustomAnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/custom-integrations/${integrationId}`,
    LOGGER_TYPE.ANALYTICS,
    { filter_by_project: filterByProject },
  );
};
