/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const StartIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V5.49359C9.12582 5.44516 9.2574 5.39571 9.39362 5.34601C10.4046 4.97719 11.7747 4.55584 12.9535 4.50101C14.4111 4.43322 15.4948 4.89896 16.3971 5.2905L16.4102 5.29617C17.3165 5.68947 18.0319 5.99993 19 5.99993C20.0468 5.99993 21.4247 5.66565 22.607 5.29559C23.184 5.11498 23.6894 4.93403 24.05 4.79834C24.2301 4.73062 24.3733 4.67446 24.4705 4.63566C24.4919 4.62709 24.5111 4.61937 24.528 4.61257C24.6686 4.53716 24.8293 4.49438 25 4.49438C25.5523 4.49438 26 4.9421 26 5.49438V16C26 16.4163 25.7457 16.7731 25.3839 16.9236C25.3858 16.9281 25.3857 16.9281 25.3857 16.9281L25.3845 16.9287L25.3816 16.9299L25.3721 16.9338L25.3379 16.9479C25.3085 16.9599 25.2662 16.9771 25.212 16.9987C25.1037 17.042 24.948 17.103 24.7542 17.1759C24.3673 17.3214 23.8255 17.5155 23.2044 17.7099C21.9906 18.0898 20.3684 18.5055 19 18.5055C17.6118 18.5055 16.563 18.0493 15.7013 17.6744L15.6009 17.6308C14.7102 17.2443 14.0015 16.96 13.0465 17.0045C12.1889 17.0444 11.059 17.373 10.0791 17.7305C9.65918 17.8837 9.28587 18.035 9 18.1561V27C9 27.5523 8.55228 28 8 28C7.44772 28 7 27.5523 7 27V5ZM24 15.3227V6.94224C23.7562 7.02644 23.4886 7.11532 23.2044 7.20428C21.9906 7.58421 20.3684 7.99993 19 7.99993C17.6118 7.99993 16.563 7.54369 15.7013 7.16881L15.6009 7.1252C14.7102 6.73868 14.0015 6.45443 13.0465 6.49885C12.1889 6.53874 11.059 6.86739 10.0791 7.22488C9.65918 7.37807 9.28587 7.52935 9 7.65053V15.9992C9.12582 15.9508 9.2574 15.9013 9.39362 15.8516C10.4046 15.4828 11.7747 15.0615 12.9535 15.0066C14.4111 14.9388 15.4948 15.4046 16.3971 15.7961L16.4102 15.8018C17.3165 16.1951 18.0319 16.5055 19 16.5055C20.0468 16.5055 21.4247 16.1713 22.607 15.8012C23.1578 15.6288 23.6433 15.4561 24 15.3227Z" />
  </BaseIcon>
);
