import {
  ALL_CONNECTION_QUERY_KEY,
  CONFIG_QUERY_KEY,
  DISCLAIMER_QUERY_KEY,
  FLOWS_QUERY_KEY,
  FUNCTIONS_QUERY_KEY,
  HANDOFFS_QUERY_KEY,
  KNOWLEDGE_BASE_QUERY_KEY,
  PRONUNCIATION_QUERY_KEY,
  SMS_CREDENTIALS_QUERY_KEY,
  SMS_TEMPLATES_QUERY_KEY,
  STOP_KEYWORDS_QUERY_KEY,
  TELEPHONE_MUTATION_KEY,
  TOPICS_QUERY_KEY,
  VARIANT_MANAGEMENT_QUERY_KEY,
  VOICE_TUNING_MUTATION_KEY,
} from 'constants/QueryKeys.constants';
import { QueryClient } from '@tanstack/react-query';

import { ProjectVersionState } from 'api/resources/deployments/types';
import { transformDisclaimerData } from 'hooks/useDeployments/utils';
import {
  ALL_ENVIRONMENTS_QUERY_KEY,
  PHONE_NUMBER_MUTATION_KEY,
} from 'hooks/usePhoneNumber';

export const setVersionState = ({
  accountId,
  projectId,
  queryClient,
  versionStateData,
  mockData = false,
}: {
  accountId: string;
  projectId: string;
  queryClient: QueryClient;
  versionStateData: ProjectVersionState;
  mockData?: boolean;
}) => {
  queryClient.setQueryData(
    [accountId, projectId, CONFIG_QUERY_KEY],
    versionStateData.config,
  );
  queryClient.setQueryData([accountId, projectId, PRONUNCIATION_QUERY_KEY], {
    pronunciations: versionStateData.config.tts_rules,
  });
  queryClient.setQueryData(
    [accountId, projectId, STOP_KEYWORDS_QUERY_KEY],
    versionStateData.stop_keywords ?? [],
  );

  if (Array.isArray(versionStateData.functions)) {
    queryClient.setQueryData([accountId, projectId, FUNCTIONS_QUERY_KEY], {
      functions: versionStateData.functions,
      active_functions: versionStateData.functions,
    });
    versionStateData.functions?.forEach((func) => {
      queryClient.setQueryData(
        [accountId, projectId, FUNCTIONS_QUERY_KEY, func.function_id],
        func,
      );
    });
  } else {
    queryClient.setQueryData(
      [accountId, projectId, FUNCTIONS_QUERY_KEY],
      versionStateData.functions,
    );
    versionStateData.functions?.active_functions.forEach((func) => {
      queryClient.setQueryData(
        [accountId, projectId, FUNCTIONS_QUERY_KEY, func.function_id],
        func,
      );
    });
  }

  if (Array.isArray(versionStateData.handoffs)) {
    queryClient.setQueryData([accountId, projectId, HANDOFFS_QUERY_KEY], {
      handoffs: versionStateData.handoffs,
      active_handoffs: versionStateData.handoffs,
    });
  } else {
    queryClient.setQueryData(
      [accountId, projectId, HANDOFFS_QUERY_KEY],
      versionStateData.handoffs,
    );
  }

  queryClient.setQueryData(
    [accountId, projectId, KNOWLEDGE_BASE_QUERY_KEY],
    versionStateData.knowledge_base,
  );

  queryClient.setQueryData([accountId, projectId, SMS_CREDENTIALS_QUERY_KEY], {
    account_id: accountId,
    project_id: projectId,
    integration: 'twilio',
  });

  queryClient.setQueryData(
    [accountId, projectId, SMS_TEMPLATES_QUERY_KEY],
    versionStateData.sms_templates,
  );

  queryClient.setQueryData([accountId, projectId, TOPICS_QUERY_KEY], {
    topics: versionStateData.topics,
  });
  queryClient.setQueryData(
    [accountId, projectId, VOICE_TUNING_MUTATION_KEY],
    versionStateData.voice_tuning_settings,
  );

  const disclaimerData = transformDisclaimerData({
    knowledge_base: versionStateData.knowledge_base,
  });

  queryClient.setQueryData(
    [accountId, projectId, DISCLAIMER_QUERY_KEY],
    disclaimerData,
  );

  queryClient.setQueryData(
    [accountId, projectId, FLOWS_QUERY_KEY],
    versionStateData.flows,
  );
  versionStateData.flows.forEach((flow) => {
    queryClient.setQueryData(
      [accountId, projectId, FLOWS_QUERY_KEY, flow.id],
      flow,
    );
  });

  const variant_management_values: Record<string, Record<string, string>> = {};
  versionStateData.variants.forEach((variant) => {
    const variant_values: Record<string, string> = {};
    versionStateData.variant_attributes.forEach((value) => {
      variant_values[value.name] = value.values[variant.id];
    });
    variant_management_values[variant.name] = variant_values;
  });

  queryClient.setQueryData(
    [accountId, projectId, VARIANT_MANAGEMENT_QUERY_KEY],
    {
      variants: versionStateData.variants,
      attributes: versionStateData.variant_attributes,
      values: variant_management_values,
    },
  );

  if (mockData) {
    queryClient.setQueryData([accountId, projectId, TELEPHONE_MUTATION_KEY], {
      connector_id: 'MOCK_CONNECTOR_ID',
      token: 'MOCK_TOKEN',
    });
    queryClient.setQueryData(
      [accountId, projectId, TELEPHONE_MUTATION_KEY, ALL_CONNECTION_QUERY_KEY],
      {
        sandbox: { token: 'MOCK_TOKEN' },
      },
    );
  }

  if (versionStateData.phone_numbers) {
    queryClient.setQueryData(
      [
        accountId,
        projectId,
        PHONE_NUMBER_MUTATION_KEY,
        ALL_ENVIRONMENTS_QUERY_KEY,
      ],
      { phone_numbers: versionStateData.phone_numbers },
    );
  }
};
