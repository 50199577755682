import { Role } from '@polyai/common/types/resources/userManagement';

export type AccountResponse = {
  id: string;
  name: string;
  created_at: string;
  created_by: string;
  active: boolean;
  conversation_limits: {
    call_duration_limit: number;
    chat_turn_limit: number;
  };
  role: Role.MEMBER | Role.ADMIN;
  type: AssistantType; 
};

export enum AssistantType {
  GENAI = 'genai',
  INTENT = 'intent',
  HYBRID = 'hybrid'
}

export type AccountsResponse = AccountResponse[];

export type AuthoriseResponse = {
  isAuthorised: boolean;
  user: {
    email: string;
    is_first_login: boolean;
  };
};
